define('toolkit/modules/header', ['toolkit/jquery', 'toolkit/modules/overlay', 'toolkit/lib/mustache'], function ($, Overlay, Mustache) {
  function initailze() {
    var MOBILE_NAV_BREAKPOINT = 760;

    var doc = document;
    var doc_el = doc.documentElement;
    var $communicate_nav;
    var $donation_nav;
    var $settings_nav;
    var $doe_nav;
    var $doe_nav_1;
    var $dashboard_nav_toggle;
    var $communicate_nav_toggle;
    var $donation_nav_toggle;
    var $settings_nav_toggle;
    var $doe_nav_toggle;
    var $doe_nav_toggle_1;
    var dashboard_nav_overlay;
    var dashboard_nav_mobile_overlay;
    var communicate_nav_overlay;
    var donation_nav_overlay;
    var settings_nav_overlay;
    var doe_nav_overlay;
    var doe_nav_overlay_1;

    $settings_nav = $('#settings_nav');
    settings_nav_overlay = new Overlay($settings_nav, {force_solo: true});
    $settings_nav_toggle = $('#settings_nav_toggle');

    $settings_nav_toggle.on('click', function (click) {
      if (window.innerWidth >= MOBILE_NAV_BREAKPOINT) {
        click.preventDefault();
        click.stopPropagation();
        settings_nav_overlay.toggle();
      }
    });

    $('#menuToggle').on('click', function (click) {
      click.preventDefault();
      $('.fb-nav').toggleClass('visible');
      $(this).toggleClass('active');
      $('body').toggleClass('nav-open');
    });

    $dashboard_nav_toggle = $('#dashboard_nav_toggle');
    $dashboard_nav_toggle.on('click', function (click) {
      click.preventDefault(); // don't follow the link
      click.stopPropagation();
      $.ajax({
        beforeSend: toggleDashboardsIfPresent, // use already queried dashboard list if present
        dataType: 'json',
        cache: false,
        url: '/dashboard/dashboards',
        success: populateDashboardListOrRedirect,
        error: function () {
          redirectToDashboard(); // error, redirect to base dashboard url
        }
      });
    });

    function populateDashboardListOrRedirect (dashboards) {
      var dashboardTypes = Object.keys(dashboards);
      if ($.isEmptyObject(dashboards) || ((dashboardTypes.length == 1 && dashboards[dashboardTypes[0]].length == 1))) {
        // redirect to base dashboard url if < 2 dashboards, let the base dashboard logic handle it
        redirectToDashboard();
      } else {
        // multiple dashboards, show the nav overlay
        // this is the hardcoded version of ../../../templates/structures/dashboard-link.mustache
        // if you are updating the 'dashboard_template' you also need to update the mustache template to match
        var dashboard_template = '<li> \
            <a href="/dashboard/{{type}}/{{id}}/{{year}}" role="button"> \
              <span class="dashboard-nav__date">{{year}}</span> \
              <span class="dashboard-nav__content"><span class="{{class}}">{{name}}</span></span> \
            </a> \
          </li>';
        var typesIteratedOn = [];
        var output = '';

        for ( var entityType in dashboards) {
          if (typesIteratedOn.indexOf(entityType) == -1) {
            typesIteratedOn.push(entityType);
            output += '<li><span class="dashboard-nav__heading">' + entityType + '</span><ul>';
          }
          if (dashboards.hasOwnProperty(entityType)) {
            $.each(dashboards[entityType], function (index, entity) {
              output += Mustache.render(dashboard_template, {
                id: entity.id,
                year: entity.year,
                class: (entity.year ? 'dashboard-nav__entity' : 'dashboard-nav_no_year__entity'),
                type: entity.type,
                name: entity.name
              });
            });
          }
          output += '</ul></li>';
        }

        $dashboard_nav_toggle.after('<nav class="list-nav overlay dashboard-nav overlay--large fullscreen" id="dashboard_nav_mobile"><ul>' + output + '</ul></nav>');
        $dashboard_nav_toggle.after('<nav class="list-nav overlay dashboard-nav" id="dashboard_nav"><ul>' + output + '</ul></nav>');
        dashboard_nav_overlay = new Overlay($('#dashboard_nav'), {force_solo: true});
        dashboard_nav_mobile_overlay = new Overlay($('#dashboard_nav_mobile'), {force_solo: true, full_screen: true});

        indicateDashboardsDoneLoading();

        if (window.innerWidth < MOBILE_NAV_BREAKPOINT) {
          dashboard_nav_mobile_overlay.toggle(); // show/hide the menu
        } else {
          dashboard_nav_overlay.toggle(); // show/hide the menu
        }

        $(window).resize(handleDashboardsLayoutChange);
      }
    }

    function toggleDashboardsIfPresent () {
      if (dashboard_nav_overlay) {
        // show/hide the menu
        if (window.innerWidth < MOBILE_NAV_BREAKPOINT) {
          dashboard_nav_mobile_overlay.toggle();
        } else {
          dashboard_nav_overlay.toggle();
        }
        return false; // return false to stop ajax call
      } else {
        indicateDashboardsLoading();
      }
    }

    function handleDashboardsLayoutChange () {
      if (window.innerWidth < MOBILE_NAV_BREAKPOINT && dashboard_nav_overlay.isShown()) {
        dashboard_nav_overlay.hide();
        dashboard_nav_mobile_overlay.show();
      } else if (window.innerWidth >= MOBILE_NAV_BREAKPOINT && dashboard_nav_mobile_overlay.isShown()) {
        dashboard_nav_mobile_overlay.hide();
        dashboard_nav_overlay.show();
      }
    }

    function redirectToDashboard (url) {
      url = (typeof url === 'undefined') ? '/dashboard' : url;
      window.location.href = url;
    }

    function indicateDashboardsLoading () {
      $dashboard_nav_toggle.addClass('spinner-before');
    }

    function indicateDashboardsDoneLoading () {
      $dashboard_nav_toggle.removeClass('spinner-before');
    }

    // new donation tooltip
    const $new_donation_tooltip_desktop = new Overlay($('.new-donation-nav.new-donation--desktop'), {force_solo: false, locked: true});
    const $new_donation_tooltip_mobile = new Overlay($('.new-donation-nav.new-donation--mobile'), {force_solo: false, locked: true});

    $('.new-donation-close').on('click', function () {
      $new_donation_tooltip_desktop.hide(null, true);
      $new_donation_tooltip_mobile.hide(null, true);
      sessionStorage.setItem('hide_new_donation_tooltip', true);
    });

    if (!sessionStorage.hide_new_donation_tooltip) {
      $new_donation_tooltip_desktop.show();
      $new_donation_tooltip_mobile.show();
    };

    // Logic for the Day of Event navigation
    var DOE = {
      $days_count: $('.js_days_count'),
      init: function () {
        // overlays and icons
        $doe_nav = $('.doe-nav');
        doe_nav_overlay = new Overlay($doe_nav, {force_solo: false, locked: true});
        $doe_nav_toggle = $('.doe-nav-toggle');

        $doe_nav_1 = $('.doe-nav-1');
        doe_nav_overlay_1 = new Overlay($doe_nav_1, {force_solo: false, locked: true});
        $doe_nav_toggle_1 = $('.doe-nav-toggle-1');

        DOE.update_day_count();
        DOE.show_hint();

        $doe_nav_toggle.on('click', function (click) {
          if (DOE.day_count < 4) {
            $doe_nav.removeClass('dark');
            DOE.populate_tools_list();
          }
          click.preventDefault();
          click.stopPropagation();
          doe_nav_overlay.toggle();
        });
        $doe_nav_toggle_1.on('click', function (click) {
          if (DOE.day_count < 4) {
            $doe_nav_1.removeClass('dark');
            DOE.populate_tools_list();
          }
          click.preventDefault();
          click.stopPropagation();
          doe_nav_overlay_1.toggle();
        });
      },
      show_hint: function () {
        var $menu = $doe_nav;
        if (!sessionStorage.hide_teaser) {
          doe_nav_overlay.show();
          doe_nav_overlay_1.show(); // Don't show if there is another overlay already open

          $('.doe-close').click(function () {
            doe_nav_overlay.hide(null, true);
            doe_nav_overlay_1.hide(null, true);
            sessionStorage.setItem('hide_teaser', true);
          });
        }
        if (DOE.day_count < 4) {
          var updated_paragraph = 'Here are some useful tools to use during your event.';
          $doe_nav.find('p').html(updated_paragraph);
          $doe_nav_1.find('p').html(updated_paragraph);
        }
        if (DOE.day_count === 1) {
          var updated_header = 'Your event is tomorrow!';
          $doe_nav.find('h4').html(updated_header);
          $doe_nav_1.find('h4').html(updated_header);
        }
        if (DOE.day_count < 1) {
          var updated_header = 'Your event is here!';
          $doe_nav.find('h4').html(updated_header);
          $doe_nav_1.find('h4').html(updated_header);
        }
      },
      populate_tools_list: function () {
        var html = '';

        for (var i = DOE.data.length - 1; i >= 0; i--) {
          var the_event = DOE.data[i];
          if (the_event.days <= 3) {
            html += '<ul class="white-list">';
            html += '<li class="dashboard-nav__heading">' + the_event.title + '</li>';
            if (the_event.allow_register) {
              html += '<li><a href="/fast-registration/' + the_event.id + '/' + the_event.year + '">Register Walk-In Participants</a></li>';
            }
            if (the_event.allow_donate) {
              html += '<li><a href="/day-of-event/donate/' + the_event.id + '/' + the_event.year + '">Process a Donation</a></li>';
            }
            html += '</ul>';
          }
        }

        $doe_nav.html(html);
        $doe_nav_1.html(html);
      },
      update_day_count: function () {
        var smallest_num = DOE.data[0].days;
        if (DOE.data) {
          if (DOE.data.length > 1) {
            for (var i = DOE.data.length - 1; i >= 0; i--) {
              if (smallest_num > DOE.data[i].days) {
                smallest_num = DOE.data[i].days;
              }
            }
          }
          DOE.day_count = smallest_num;
          DOE.$days_count.html(smallest_num || '!');
        }
      }
    };

    $(function () {
      if (window.sbf && window.sbf.logged_in) {
        $.ajax({
          type: 'GET',
          url: '/day-of-event/index.json',
          complete: function (e) {
            var json = e.responseJSON;

            if (json && json.length) {
              DOE.data = json;
              DOE.init();
            }
          }
        });
      }
      if (window.sbf && window.sbf.debug) {
        window.sbf.DOE = DOE;
      }
    });

    return DOE;
  }

  initailze();

  return initailze;
});
