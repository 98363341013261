define(
  'toolkit/modules/forms/base',
  ['toolkit/jquery', 'toolkit/lib/jquery.customSelect'],

  function ($) {
    function input_is_empty ($input) {
      var $select = $input.find('select');

      if ($input.find('input') && $input.find('input').val() === '') {
        return true;
      }

      if ($input.find('textarea') && $input.find('textarea').val() === '') {
        return true;
      }

      if ($select.length) {
        var $selected = $select.eq(0).find(':selected');
        if ($selected.prop('disabled') || $selected.val() === '') {
          return true;
        }
      }

      return false;
    }

    function initailze () {
      // Initializing custom input stylings
      $('.inline-input').each(function () {
        var $con = $(this);

        if (input_is_empty($con)) {
          $con.addClass('empty');
        } else {
          $con.removeClass('empty');
        }
      });

      $('.inline-input select').change(function () {
        var $con = $(this).parents('.inline-input');
        var $select = $(this);

        if ($select.hasClass('customSelectDisabledOption') || !$select.val()) {
          $con.addClass('empty');
        } else {
          $con.removeClass('empty');
        }
      });

      $('.inline-input').on('change keyup', 'input', function () {
        var $con = $(this).parents('.inline-input');

        if ($(this).val() !== '') {
          $con.removeClass('empty');
        } else {
          $con.addClass('empty');
        }
      });

      $('.inline-input').on('change keyup', 'textarea', function () {
        var $con = $(this).parents('.inline-input');

        if ($(this).val() !== '') {
          $con.removeClass('empty');
        } else {
          $con.addClass('empty');
        }
      });
    }

    initailze();

    return initailze;
  }
);
