define('toolkit/modules/overlay', ['toolkit/jquery'], function ($) {
  // set to true to log debug type messages
  var DEBUG_MODE = false;
  function log () {
    if (DEBUG_MODE && window.console) {
      console.log.apply(console, arguments);
    }
  }

  // warnings will be shown regardless of DEBUG_MODE
  function warn () {
    if (window.console) {
      console.warn.apply(console, arguments);
    }
  }

  // nasty hack part I:
  // I've been running in to some weird issues where overlays behave very oddly
  // on iPad if the document is not scrolled to the top.
  // For now, we're sniffing (I know, I know...) iPad, scrolling to the top when
  // showing fullscreen overlays, then returning the scroll position when the
  // overlay is hidden.
  var IS_IPAD = navigator.userAgent.match(/iPad/i) != null;

  // holds currently visible overlays
  var visible = [];

  var fullscreen_initialized = false;
  var $body;
  var $html;
  var $mask;
  var $container;
  var mask_z;

  var ESC = 27;

  var last_show_time;
  var CLOSE_HANDLER_PAUSE_TIME = 200; // # of ms to disable the doc_click_listener after an overlay is shown

  var pending_overlay; // an overlay in the process of being shown

  // default options for overlays
  var default_opts = {
    force_solo: true, // should opening this overlay force close all other overlays?
    full_screen: false, // should the overlay be placed front and center?
    locked: false // locked overlays can only be closed programatically
  };

  /* Overlay event listeners work like this:
   *
   * $('#participant_overlay').on('overlay.beforeShow', function () {
   *    alert('before show');
   * });
  **/

  var original_scroll_offset;

  function Overlay ($el, opts) {
    // check for existing instance
    var instance = $el.data('sbf-overlay');
    if (instance) {
      return instance;
    }

    // save this instance on the element to prevent multiple instances per element
    $el.data('sbf-overlay', this);

    this.$el = $el;

    this.opts = $.extend({}, default_opts, opts || {});
    this._shown = false;

    if (this.opts.full_screen) {
      if (!fullscreen_initialized) {
        initialize_full_screen();
      }
      $container.append($el);
      // setup fullscreen stuff
      // TODO: if supporting multiple full screen overlays, z-index stuff will need to be reworked
      this._z = mask_z + 2; // changed this to +2 so the container can be +1
      this.$el.css('z-index', -1);
    }

    // convenient way to create triggers
    var overlay = this;
    if (this.opts.triggers) {
      this.opts.triggers.forEach(function ($trigger) {
        overlay.createTrigger($trigger);
      });
    }
    if (this.opts.toggles) {
      this.opts.toggles.forEach(function ($toggle) {
        overlay.createToggle($toggle);
      });
    }
  }

  Overlay.prototype = {
    constructor: Overlay,

    show: function (e) {
      // `e` is an optional parameter containing the event that triggered the show
      log('show:', this);

      if (!this.isShown()) {
        pending_overlay = this;

        if (this.opts.force_solo) {
          log('calling hideAll from #show', this);
          Overlay.hideAll();
        }

        // for now, this is getting called after others are closed
        // usage will determine if that makes the most sense
        this.$el.trigger('overlay.beforeShow', [e]);

        if (this.opts.full_screen) {
          // if overlay is locked, don't show the close button
          if (this.opts.locked) {
            $('.overlay__close').hide();
          } else {
            $('.overlay__close').show();
          }

          $container.show();
          $mask.fadeIn('fast');
          this.$el.css('z-index', this._z);
          // put the container between the mask and the overlay
          $container.css('z-index', this._z - 1);
          // nasty hack part II
          if (IS_IPAD) {
            original_scroll_offset = $(document).scrollTop();
            $(document).scrollTop(0);
          }
        }

        log('now showing overlay:', this);
        this.$el.addClass('overlay--shown');
        this._setShown(true);
        this._current_index = visible.push(this) - 1;
        pending_overlay = null;
        this.$el.trigger('overlay.afterShow', [e]);

        last_show_time = (new Date).getTime();
      }
    },

    hide: function (e, unlock) {
      // `e` is an optional parameter containing the event that triggered the hide
      // you must set `unlock` to true to hide "locked" overlays
      log('hide:', this);
      log('event that triggered hide:');
      log(e);

      if (this.opts['locked'] && unlock !== true) {
        log('overlay not unlocked, ignoring `hide`');
        // trigger an event that allows you to listen for when a user
        // tries to close a locked overlay.
        this.$el.trigger('overlay.preventedHide', [e]);
        return false;
      } else {
        log('overlay unlocked');
      }

      if (this.isShown()) {
        this.$el.trigger('overlay.beforeHide', [e]);
        this.$el.removeClass('overlay--shown');
        this._setShown(false);
        visible.splice(this._current_index, 1);

        // check for a overlay currently being opened before closing the mask & container
        if (this.opts.full_screen && !pending_overlay) {
          log('ending overlay mode');

          // nasty hack part III
          if (IS_IPAD && original_scroll_offset !== undefined) {
            $(document).scrollTop(original_scroll_offset);
            original_scroll_offset = undefined;
          }

          $mask.fadeOut('fast');
          this.$el.css('z-index', -1);
          $('.overlay__close').hide();
          $container.hide();
        }
        this.$el.trigger('overlay.afterHide', [e]);
      }
    },

    reset: function () {
      var $form;
      var $el = this.$el;

      if ($el.is('form')) {
        $form = $el;
      } else if ($el.find('form')) {
        $form = $el.find('form');
      } else {
        return false;
      }

      $.each($form, function (index, value) {
        $form[index].reset();
      });

      $form.find('.inline-input').each(function () {
        var $con = $(this);
        var $in = $con.find('input');
        var $sel = $con.find('select');

        if ($in.length || $sel.length) {
          if ($in.val() === '' || $sel.val() === '') {
            $con.addClass('empty');
          } else {
            $con.removeClass('empty');
          }
        }

        // for the CustomSelect library
        $sel.trigger('render');
      });
    },

    // if locked, clicking the mask will not close the overlay
    lock: function () {
      this.opts.locked = true;
      this.$el.addClass('is-locked');
    },

    unlock: function () {
      this.opts.locked = false;
      this.$el.removeClass('is-locked');
    },

    // block the overlay (useful when doing an ajax save)
    block: function () {
      this.$el.addClass('is-blocked');
    },

    unblock: function () {
      this.$el.removeClass('is-blocked');
    },

    toggle: function (e) {
      // `e` is an optional parameter containing the event that triggered the toggle
      log('toggle: ', this);

      if (this.isShown()) {
        this.hide(e);
      } else {
        this.show(e);
      }
    },

    isShown: function () {
      return this._shown;
    },

    _setShown: function (shown) {
      this._shown = shown;
    },

    createTrigger: function ($trigger_element) {
      var overlay = this;
      this.$trigger = $trigger_element;

      $trigger_element.on('click', function (click) {
        overlay.$trigger = $(this);
        click.preventDefault();
        click.stopPropagation();
        overlay.show(click);
      });
    },

    createToggle: function ($toggle_element) {
      var overlay = this;
      $toggle_element.on('click', function (click) {
        click.preventDefault();
        click.stopPropagation();
        overlay.toggle(click);
      });
    },

    loading: function () {
      log('loading...');
      var $spinner = this.$el.find('.spinner');
      log($spinner);
      if ($spinner.length) {
        $spinner.show();
      } else {
        this.$el.find('overlay__heading').after('<div class="spinner"></div>');
      }
    },

    doneLoading: function () {
      var $spinner = this.$el.find('.spinner');
      if ($spinner.length) {
        $spinner.hide();
      }
    }
  };

  Overlay.hideAll = function (event) {
    log('hideAll:', event);

    if (event) {
      event.preventDefault();
    }

    for (var i = 0, l = visible.length; i < l; i++) {
      visible[i].hide();
    }
    log('hideAll finished');
  };

  $(function initialize () {
    $(document).on('click touchend', doc_click_listener).on('keyup', doc_escape_key_listener);
    $(document).on('click', '.overlay__close', function (click) {
      click.stopPropagation();
      click.preventDefault();
      $(document).trigger('click');
    });
  });

  function initialize_full_screen () {
    if (fullscreen_initialized) {
      return;
    }

    log('initialize_full_screen');

    $body = $('body');
    $html = $('html');
    $mask = $('<div class="overlays-mask"></div>');
    $container = $('<div class="overlays-container"></div>');

    $mask.css('display', 'none');
    $body.append($mask);
    $body.append($container);
    $container.after('<a href="#" class="overlay__close">&times;</a>');
    mask_z = $mask.css('z-index');
    fullscreen_initialized = true;
  }

  // listens for document clicks and closes overlays as necessary
  function doc_click_listener (click) {
    log('doc_click_listener');
    log(click.target);

    var now = (new Date).getTime();

    if (last_show_time && (now - last_show_time < CLOSE_HANDLER_PAUSE_TIME)) {
      // it's too soon to close the overlay
      log('doc_click ignored due to CLOSE_HANDLER_PAUSE_TIME');
      return true;
    }

    // clicking on a banner should not close an overlay
    log('checking if click was on a banner');
    log(click.target);
    if ($(click.target).closest('.mypage--banner').length) {
      return true;
    }

    // TODO: if stacking overlays, there may need to be more logic in here to make sure we don't close things that shouldn't be closed
    for (var i = 0, l = visible.length; i < l; i++) {
      var original_target = click.target;
      var parent_target = visible[i].$el;

      if (!$(original_target).closest(parent_target).length) {
        visible[i].hide(click);
      }
    }
  }

  function doc_escape_key_listener (e) {
    log('doc_escape_key_listener', e);
    if (e.keyCode === ESC) {
      log('calling hideAll from doc_escape_key_listener');
      Overlay.hideAll();
    }
  }

  function window_resize_handler () {
    //
  }
  return Overlay;
});
