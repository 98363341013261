import 'lib/mustache';
import 'toolkit/modules/header';
import 'toolkit/modules/overlay';
import svg4everybody from 'toolkit/modules/svg4everybody';
import 'modules/compose-linker';
import 'jquery-ujs';
import 'toolkit/modules/forms/base';

// Polyfill for SVGs
svg4everybody();

$('#secondaryNav').on('change', function (event) {
  if ($(this).val()) {
    const $option = $(this).find(':selected');
    if ($option.val() !== 'Select a Dashboard') {
      window.location = $option.val();
    }
  }
});
